.NavLink {
  font-size: 1rem;
  text-decoration: none;
}
.NavLink button {
  height: 20px;
  color: rgb(0 144 99);
  font-weight: bolder;
}
.MuiTabs-indicator {
  background-color: #12aa04 !important;
}
